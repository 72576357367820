/*----------------------------------------*/
/*  07. Newsletter
/*----------------------------------------*/
.newsletter{
    &-title{
        text-transform: uppercase;
        color: #444444;
        font-weight: 700;
        font-size: 48px;
        @include media-breakpoint-down(lg){
            font-size: 30px;
        }
    }
    &-sub-title{
        text-transform: uppercase;
        font-size: 36px;
        @include media-breakpoint-down(lg){
            font-size: 25px;
        }
    }
    &-form{
        display: flex;
        &.wide{
            flex-direction: column;
            .input-field{
                width: 100%;
                height: 45px;
            }
            .button{
                &-wrap{
                    .btn{
                        width: 100%;
                        &:before{
                            width: calc(100% + 15px);
                        }
                    }
                }
            }

        }
        @include media-breakpoint-down(sm){
            flex-direction: column;
        }
        .input-field{
            border: 1px solid #eeeeee;
            background-color: #eeeeee;
            padding-left: 15px;
            padding-right: 15px;
            width: 315px;
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            margin-right: 2px;
            &:placeholder-shown{
                color: #a2a1a1;
            }
            @include media-breakpoint-down(lg){
                width: 100%;
                height: 45px;
            }
        }
        .btn{
            &-custom-size{
                width: 135px;
                height: 45px;
                border-end-end-radius: 3px;
                border-top-right-radius: 3px;
                @include media-breakpoint-down(sm){
                    margin-top: 15px;
                    width: 100%;
                    height: 45px;
                    &:before{
                        width: calc(100% + 30px);
                    }
                }
            }
        }
    }
}

/* ---Mailchimp--- */
.mailchimp{
    &-success{
        color: green;
    }
    &-error{
        color: red;
    }
}