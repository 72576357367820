/*----------------------------------------*/
/*  05. Product
/*----------------------------------------*/
.product{
    &-slider{
        padding-bottom: 4px;
        margin-bottom: -4px;
    }
    &-item{
        box-shadow: 0px 5px 2px -1px rgba(255,233,233,1);
        background-color: $white;
        position: relative;
        height: 100%;
        overflow: hidden;
        z-index: 1;
        border-end-start-radius: 4px;
        &-wrap{
            height: 100%;
        }
        &:hover{
            .product{
                &-content{
                    .price-box{
                        visibility: hidden;
                        opacity: 0;
                    }
                }
                &-add-action{
                    bottom: -15px;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    &-img{
        height: 100%;
        .img-full{
            height: 100%;
        }
    }
    &-content{
        position: absolute;
        bottom: 35px;
        left: 30px;
        .product{
            &-name{
                display: block;
                color: $heading;
                font-weight: 500;
                &:hover{
                    color: $primary;
                }
            }
        }
        .price-box{
            transition: $baseTransition;
            visibility: visible;
            opacity: 1;
            span{
                font-size: 14px;
                color: $heading;
            }
        }
    }
    &-add-action{
        transition: $baseTransition;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        bottom: -65px;
        left: 0;
        border-top-left-radius: 3px;
        border-end-start-radius: 3px;
        ul{
            display: flex;
            li{
                display: inline-block;
                &:not(:last-child){
                    margin-right: 10px;
                }
                a{
                    i{
                        border: 1px solid $border-color;
                        transition: $baseTransition;
                        width: 30px;
                        height: 30px;
                        line-height: 28px;
                        text-align: center;
                        display: block;
                        &:hover{
                            background-color: $primary;
                            border-color: $primary;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    &-banner{
        box-shadow: 0px 5px 2px -1px #ffe9e9;
        position: relative;
        height: 100%;
        &-img{
            height: 100%;
            @include media-breakpoint-down(lg){
                &.fixed{
                    &-height{
                        height: 405px;
                    }
                }
            }
            .img-full{
                height: 100%;
                @include media-breakpoint-down(lg){
                    height: auto;
                }
            }
        }
        &-content{
            position: absolute;
            top: 80px;
            left: 30px;
            .category{
                text-transform: uppercase;
                font-size: 18px;
            }
            .offer{
                text-transform: uppercase;
                font-size: 36px;
                @include media-breakpoint-down(xl){
                    font-size: 30px;
                }
            }
            .short-desc{
                line-height: 26px;
            }
        }
    }
    &-custom{
        &-col{
            flex: 0 0 auto;
            width: 16.66667%;
            @include media-breakpoint-down(lg){
                width: 33.33333%;
            }
            @include media-breakpoint-down(sm){
                width: 50%;
            }
        }
    }
    &-category{
        &-img{
            overflow: hidden;
            display: block;
            img{
                width: 100%;
            }
        }
        &-content{
            text-align: center;
            .title{
                a{
                    color: $heading;
                    font-size: 18px;
                    font-weight: 400;
                    display: block;
                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
    }
    &-list-view{
        .product{
            &-list{
                &-item{
                    box-shadow: 0px 5px 2px -1px rgba(255,233,233,1);
                    border-end-start-radius: 4px;
                    background-color: $white;
                    height: 100%;
                    display: flex;
                    padding: 4px;
                    margin: -4px;
                    @include media-breakpoint-down(lg){
                        flex-direction: column;
                    }
                }
                &-img{
                    width: 350px;
                    @include media-breakpoint-down(lg){
                        width: 100%;
                    }
                }
                &-content{
                    width: calc(100% - 350px);
                    @include media-breakpoint-down(lg){
                        width: 100%;
                    }
                    .product{
                        &-name{
                            display: block;
                            font-size: 20px;
                            font-weight: 500;
                        }
                        &-add-action{
                            opacity: 1;
                            visibility: visible;
                            position: relative;
                            top: 0;
                            padding-top: 25px;
                            @include media-breakpoint-down(lg){
                                padding-bottom: 25px;
                            }
                            ul{
                                li{
                                    i{
                                        font-size: 18px;
                                        width: 45px;
                                        height: 45px;
                                        line-height: 45px;
                                    }
                                }
                            }
                        }
                    }
                    .rating-box{
                        ul{
                            li{
                                i{
                                    color: $primary;
                                }
                            }
                        }
                    }
                    .short-desc{
                        color: #3e3e3f;
                        line-height: 1.8;
                    }
                }
            }
        }
        &.with-sidebar{
            .product{
                &-list{
                    &-img{
                        @include media-breakpoint-only(lg){
                            width: 250px;
                        }
                    }
                    &-content{
                        @include media-breakpoint-only(lg){
                            width: calc(100% - 250px);
                        }
                        .product{
                            &-add-action{
                                @include media-breakpoint-only(lg){
                                    padding-bottom: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-info{
        width: 150px;
    }
    &-tab{
        &-nav{
            background-color: #f4f4f4;
            justify-content: center;
            li{
                a{
                    &.tab-btn{
                        padding-top: 20px;
                        padding-bottom: 20px;
                        text-align: center;
                        width: 130px;
                        display: block;
                        transition: $baseTransition;
                    }
                    &.active{
                        background-color: $primary;
                        color: $white;
                    }
                }
            }
        }
    }
    &-description{
        &-body{
            .short-desc{
                text-align: center;
                color: #6c788c;
                line-height: 30px;
            }
        }
    }
}

/* ---Special Deals--- */
.special-deals{
    &-item{
        border: 1px solid #e7e7e7;
    }
    &-button-wrap{
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}

/* ---New Product--- */
.new-product{
    &-item{
        border: 1px solid #e7e7e7;
    }
}

/* ---Feedback--- */
.feedback{
    &-area{
        padding-left: 40px;
        padding-right: 40px;
        &.with-bg{
            background-color: #f9f9f9;
            padding-top: 50px;
            padding-bottom: 50px;
            .feedback{
                &-form{
                    .input-field,
                    .textarea-field{
                        background-color: #f9f9f9;
                    }
                }
            }
        }
        .heading{
            position: relative;
            line-height: 24px;
            &:before{
                background-color: $primary;
                content: "";
                width: 9px;
                height: 17px;
                position: absolute;
                top: 0;
                left: -40px;
            }
        }
        .rating-box{
            display: flex;
            @media (max-width: 479px) {
                flex-direction: column;
                align-items: flex-start;
                ul{
                    padding-left: 0 !important;
                    padding-top: 10px;
                }
            }
            span{
                color: #444444;
            }
        }
    }
    &-form{
        .group-input{
            display: flex;
            @include media-breakpoint-down(md){
                flex-direction: column;
            }
        }
        .form-field{
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        .input-field,
        .textarea-field{
            border: 1px solid $border-color;
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
            height: 50px;
        }
        .textarea-field{
            padding: 20px;
            height: 150px;
        }
    }
}

/* ---Single Product--- */
.single{
    &-product{
        &-img{
            &.with-border{
                box-shadow: 0px 5px 2px -1px rgba(255,233,233,1);
                border: 1px solid $border-color;
            }
        }
        &-slider{
            border: 1px solid $border-color;
            margin-bottom: 10px;
            .single-img{
                display: block;
            }
        }
        &-content{
            &.with-sticky{
                position: sticky;
                top: 115px;
            }
            > .title{
                color: #444444;
                font-size: 30px;
            }
            .rating-box{
                &-wrap{
                    display: flex;
                    @media (max-width: 479px){
                        flex-direction: column;
                        .review-status{
                            padding-left: 0 !important;
                            padding-top: 5px;
                        }
                    }
                    .review-status{
                        span{
                            font-size: 14px;
                        }
                    }
                }
            }
            .short-desc{
                color: #6c788c;
                line-height: 30px;
            }
            .quantity-with-btn{
                display: flex;
                @media (max-width: 479px) {
                    flex-wrap: wrap;
                    justify-content: center;
                }
                li{
                    .cart-plus-minus{
                        width: 100%;
                        .cart-plus-minus-box{
                            @media (max-width: 479px){
                                width: 100%;
                            }
                        }
                    }
                    &.add-to-cart{
                        .btn{
                            border-radius: 25px;
                        }
                    }
                    a{
                        &.custom-circle-btn{
                            border-radius: 100%;
                            padding: 0;
                            i{
                                background-color: #3d3d3d;
                                text-align: center;
                                color: $white;
                                border-radius: 100%;
                                width: 50px;
                                height: 50px;
                                line-height: 50px;
                                font-size: 18px;
                                display: block;
                                transition: $baseTransition;
                            }
                            &:hover{
                                i{
                                    background-color: $primary;
                                    color: $white;
                                }
                            }
                        }
                    }
                    &:not(:last-child){
                        padding-right: 10px;
                        @media (max-width: 479px) {
                            padding-bottom: 10px;
                        }
                    }
                }
            }
            .price-box{
                span{
                    font-size: 24px;
                }
            }
            .product{
                &-category{
                    display: flex;
                    align-items: center;
                    @media (max-width: 479px){
                        flex-wrap: wrap;
                    }
                    .title{
                        margin-right: 10px;
                        color: #5f5f5f;
                        font-weight: 500;
                        font-size: 16px;
                    }
                    ul{
                        li{
                            display: inline-block;
                            a{
                                color: #9f9e9e;
                                &:hover{
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
            }
            .social-link{
                ul{
                    li{
                        &:not(:last-child){
                            padding-right: 15px;
                        }
                    }
                }
            }
        }
        &-thumbs{
            .swiper{
                &-slide{
                    border: 1px solid $border-color;
                    transition: $baseTransition;
                    &:hover{
                        opacity: 1;
                    }
                }
            }
        }
    }
}

/* ---Group quantity--- */
.grouped-qty{
    color: #505050;
    font-size: 14px;
    line-height: 29px;
    .price-box{
        span{
            font-size: 14px;
        }
    }
    .in-stock{
        display: flex;
        align-items: center;
        i{
            margin-right: 5px;
        }
    }
    li{
        border-bottom: 1px solid $border-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        @media (max-width: 479px){
            flex-direction: column;
            text-align: center;
            .product{
                &-info{
                    padding-top: 10px;
                }
            }
        }
        &:not(:first-child){
            padding-top: 15px;
        }
    }
}

/* ---Selector Wrap--- */
.selector{
    &-wrap{
        display: flex;
        align-items: center;
        @media (max-width: 479px) {
            flex-direction: column;
        }
        .nice-select{
            &:focus,
            &.open{
                border-color: #e8e8e8;
            }
        }
    }
    &-title{
        border-top: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        height: 42px;
        line-height: 40px;
        padding-left: 30px;
        padding-right: 30px;
        width: 150px;
        text-align: center;
        @media (max-width: 479px) {
            width: 100%;
            border-right: 1px solid #e8e8e8;
            border-bottom: 0;
        }
    }
}