/*----------------------------------------*/
/*  04. Navigation
/*----------------------------------------*/
/* ---Swiper Arrow--- */
.swiper{
	&-button-next,
	&-button-prev{
		background-color: $dark;
		color: $white;
		width: 50px;
		height: 50px;
		line-height: 50px;
		opacity: 0;
        visibility: hidden;
		transition: $baseTransition;
		@include media-breakpoint-down(md){
			display: none;
		}
		&:after{
			font-size: 16px;
		}
		&:hover{
			background-color: $primary;
			color: $white;
		}
	}
	&-arrow{
		&:hover{
			.swiper{
				&-button-next,
				&-button-prev{
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	&-arrow-sm-size{
		.swiper{
			&-button-next,
			&-button-prev{
				width: 30px;
				height: 30px;
				line-height: 30px;
				&:after{
					font-size: 12px;
				}
			}
		}
	}
	&-arrow{
		&.with-radius{
			.swiper{
				&-button-next,
				&-button-prev{
					color: $primary;
					border-radius: 100%;
					&:hover{
						background-color: $primary;
						border-color: $primary;
						color: $white;
					}
				}
			}
		}
	}
}

/* ---Custom Arrow--- */
.swiper{
	&-container{
		&:hover{
			.custom{
				&-button{
					&-prev,
					&-next{
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
	}
}
.custom{
	&-button{
		&-prev,
		&-next{
			background-repeat: no-repeat;
			position: absolute;
			top: 50%;
			left: 30px;
			z-index: 1;
			width: 29px;
			height: 29px;
			transform: translateY(-50%);
			overflow: hidden;
			transition: $baseTransition;
			opacity: 0;
			visibility: hidden;
			&:hover{
				background-position: 0 100%;
			}
		}
		&-prev{
			background-image: url('../images/slider/arrow/left-arrow.png');
		}
		&-next{
			background-image: url('../images/slider/arrow/right-arrow.png');
			left: auto;
			right: 30px;
		}
	}
}

.thumbs{
	&-arrow-holder{
		position: relative;
		padding-left: 45px;
		padding-right: 45px;
		.swiper{
			&-container{
				padding-left: 5px;
				padding-right: 5px;
				margin-left: -5px;
				margin-right: -5px;
			}
		}
		&:hover{
			.swiper{
				&-slide{
					opacity: 0.7;
				}
			}
			.thumb{
				&-button-prev,
				&-button-next{
					opacity: 0.7;
				}
			}
		}
	}
    &-button-prev,
	&-button-next{
		background-color: $dark;
		border-color: $dark;
		border-radius: 0;
		text-align: center;
		color: $white;
        width: 30px;
        height: 30px;
        line-height: 30px;
		transform: $baseTransition;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		&:hover{
			background-color: $primary;
			color: $white;
		}
		&:focus{
			outline: 0;
		}
		i{
			font-size: 30px;
			display: block;
		}
    }
	&-button-next{
		left: auto;
		right: 0;
	}
}

/* ---Product Arrow--- */
.product{
	&-button{
		&-wrap{
			display: flex;
		}
		&-prev{
			margin-right: 3px;
		}
		&-next,
		&-prev{
			i{
				background-color: #000000;
				font-size: 28px;
				color: $white;
				width: 28px;
				height: 22px;
				line-height: 22px;
				text-align: center;
				display: block;
				transition: $baseTransition;
				border-top-left-radius: 3px;
				border-end-start-radius: 3px;
				&:hover{
					background-color: $primary;
				}
			}
	
		}
	}
}
/* ---Special Deals Arrow--- */
.special-deals{
	&-button{
		&-wrap{
			display: flex;
		}
		&-next,
		&-prev{
			i{
				background-color: $white;
				color: $primary;
				font-size: 30px;
				width: 28px;
				height: 22px;
				line-height: 22px;
				text-align: center;
				display: block;
				transition: $baseTransition;
				&:hover{
					background-color: $primary;
					color: $white;
				}
			}
	
		}
		&-prev{
			margin-right: 3px;
			i{
				border-top-left-radius: 3px;
				border-end-start-radius: 3px;
			}
		}
		&-next{
			i{
				border-top-right-radius: 3px;
				border-end-end-radius: 3px;
			}
		}
	}
}


/* ---With Background--- */
.with-bg_white{
	.swiper{
		&-button-next,
		&-button-prev{
			background-color: $white;
			border: 0;
			color: inherit;
			&:hover{
				background-color: $primary;
				color: $white;
			}
		}
	}
}

/* ---Swiper Pagination--- */
.swiper-pagination{
	&-bullet{
		&-active{
			background-color: $primary;
		}
	}
	&.with-bg{
		.swiper-pagination{
			&-bullet{
				background-color: $white;
				opacity: 1;
				&-active{
					background-color: $primary;
				}
			}
		}
	}
	&.without-absolute{
		position: relative;
		padding-top: 20px;
		bottom: 0;
	}
}