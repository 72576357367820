/*----------------------------------------*/
/*  03. Slider
/*----------------------------------------*/
/* ---Main Slider--- */
.slide{
	&-inner{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		&.bg-height{
			min-height: 792px;
			@include media-breakpoint-down(xxl){
				min-height: 610px;
			}
			@include media-breakpoint-down(md){
				min-height: 480px;
			}
		}
	}
	&-content{
		@include media-breakpoint-down(lg){
			text-align: center;
		}
		.sub-title{
			font-weight: 400;
			font-size: 36px;
			line-height: 30px;
			display: block;
			text-transform: uppercase;
			@include media-breakpoint-down(md){
				font-size: 25px;
			}
		}
		.title{
			font-size: 78px;
			text-transform: uppercase;
			@include media-breakpoint-down(md){
				font-size: 40px;
			}
			&.font-weight-bold{
				font-weight: 700;
			}
		}
		.short-desc{
			text-transform: uppercase;
			font-size: 24px;
			line-height: 30px;
			@include media-breakpoint-down(md){
				font-size: 16px;
				text-transform: capitalize;
			}
		}
	}
	&-style{
		&-2{
			.slide{
				&-content{
					.title{
						text-transform: capitalize;
						font-weight: 700;
						font-size: 60px;
						line-height: 81px;
						@include media-breakpoint-down(xxl){
							font-size: 50px;
							line-height: 71px;
						}
						@media (max-width: 479px) {
							font-size: 30px;
							line-height: 1.4;
						}
					}
					.short-desc{
						text-transform: capitalize;
						font-size: 30px;
						line-height: 28px;
						font-weight: 300;
						@media (max-width: 479px) {
							font-size: 20px;
						}
					}
				}
			}
		}
	}
}