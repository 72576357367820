/*----------------------------------------*/
/*  09. Testimonial
/*----------------------------------------*/
.testimonial{
    &-item{
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 45px;
        padding-right: 45px;
        &.swiper{
            &-slide{
                &-active{
                    background-color: $white;
                    border-top-left-radius: 50px;
                    border-top-right-radius: 50px;
                    border-end-start-radius: 50px;
                    position: relative;
                    &:before{
                        content: url('../images/testimonial/user/inner-icon/1.png');
                        position: absolute;
                        bottom: -6px;
                        right: 0;
                    }
                    .user{
                        &-shape{
                            img{
                                filter: grayscale(0);
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        .user{
            &-info{
                display: flex;
            }
            &-shape{
                img{
                    transition: $baseTransition;
                    filter: grayscale(100%);
                    opacity: 0.3;
                }
                &-wrap{
                    display: inline-block;
                    position: relative;
                }
            }
            &-img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 50px;
            }
            &-content{
                align-self: center;
                padding-left: 10px;
            }
            &-name{
                text-transform: uppercase;
                font-size: 18px;
                color: #1d1d1d;
            }
            &-occupation{
                color: #1d1d1d;
                font-weight: 400;
            }
            &-comment{
                color: #161616;
                font-style: italic;
                line-height: 27px;
                max-width: 315px;
                font-weight: 300;
            }
        }
    }
    &-pagination {
        display: flex;
        justify-content: center;
        padding-top: 60px;
        .swiper{
            &-pagination{
                &-bullet{
                    width: 10px;
                    height: 10px;
                }
                &-bullet-active{
                    position: relative;
                    margin-right: 65px !important;
                    &:before{
                        background-color: $primary;
                        width: 45px;
                        height: 2px;
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 20px;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}