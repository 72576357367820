/*----------------------------------------*/
/*  13. Shop
/*----------------------------------------*/
.product{
    &-topbar{
        > ul{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include media-breakpoint-down(sm){
                flex-direction: column;
            }
            > li{
                &:not(:last-child){
                    @include media-breakpoint-down(sm){
                        margin-bottom: 20px;
                    }
                }
            }
            li{
                a{
                    color: #6c6c6c;
                }
                &.product-view-wrap{
                    border: 1px solid $border-color;
                    border-radius: 30px;
                    padding: 20px 25px;
                    @include media-breakpoint-only(sm){
                        padding: 15px 20px;
                    }
                    > ul{
                        li{
                            &:not(:last-child){
                                padding-right: 20px;
                            }
                            a{
                                &.active{
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                &.page-count{
                    border: 1px solid $border-color;
                    border-radius: 30px;
                    padding: 20px 25px;
                    @include media-breakpoint-only(sm){
                        padding: 15px 20px;
                    }
                    span{
                        color: $primary;
                    }
                }
                &.short{
                    border: 1px solid $border-color;
                    border-radius: 30px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 15px;
                    padding-right: 15px;
                    @include media-breakpoint-only(sm){
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                    .nice-select{
                        border: 0;
                        .list{
                            width: auto;
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    &-list-view{
        .product{
            &-item{
                display: flex;
                transition: $baseTransition;
                @include media-breakpoint-down(md){
                    flex-direction: column;
                }
                &:hover{
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
                }
            }
            &-img{
                width: 25%;
                @include media-breakpoint-down(lg){
                    width: 40%;
                    img{
                        height: 100%;
                    }
                }
                @include media-breakpoint-down(md){
                    width: 100%;
                }
            }
            &-content{
                padding-top: 0;
                padding-bottom: 0;
                border: 0;
                width: 75%;
                text-align: left;
                @include media-breakpoint-down(lg){
                    width: 60%;
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
                @include media-breakpoint-down(md){
                    width: 100%;
                }
                .product-name{
                    display: block;
                }
                .rating-box{
                    ul{
                        justify-content: flex-start;
                    }
                }
                .short-desc{
                    font-size: 14px;
                    line-height: 26px;
                }
            }
        }
    }
}