/*----------------------------------------*/
/* 23. 404
/*----------------------------------------*/
.error{
    &-404{
        &-area{
            padding-bottom: 175px;
        }
        &-content{
            .title{
                text-shadow: 9px 4px 0px #ebc2c2;
                color: $primary;
                font-size: 199px;
                font-weight: 900;
                letter-spacing: 26px;
                line-height: 168px;
                margin-bottom: 22px;
            }
            .sub-title{
                color: #444444;
                font-weight: 600;
                font-size: 25px;
                span{
                    font-size: 68px;
                    color: $primary;
                    vertical-align: sub;
                }
            }
            .short-desc{
                color: #3e3e3f;
                max-width: 590px;
                line-height: 1.5;
            }
        }
        &-img{
            background-color: #f58282;
            border-radius: 100%;
            width: 500px;
            height: 500px;
            margin-left: auto;
            position: relative;
            top: 50px;
            .layer{
                &:before{
                    background-color: #f06e6e;
                    border-radius: 100%;
                    content: "";
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &:after{
                    background-color: #f06e6e;
                    border-radius: 100%;
                    content: "";
                    width: 85px;
                    height: 85px;
                    position: absolute;
                    top: -50px;
                    right: 50%;
                    transform: translateX(50%);
                    z-index: 1;
                    animation: slideInRight 4s linear infinite alternate both;
                    z-index: 1;
                }
            }
            .single-img{
                img{
                    margin-right: 37px;
                    margin-top: 97px;
                    position: relative;
                }
            }
        }
    }
}